import React, { useEffect, useState } from 'react'
import classes from './construction.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { motion } from 'framer-motion'
import 'swiper/css/pagination'
import { Pagination, Navigation } from 'swiper/modules'

function Construction() {
  const [categories, setCategory] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
		fetch("https://api.shoh-palace.tj/category/?residence=7")
		  .then((response) => response.json())
		  .then((categories) => setCategory(categories));
	  }, []);

	  if (!categories) {
		return <>loading...</>;
	  }

const allPhotoReport = categories.flatMap((category) => category.images)
	
console.log(categories)

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value
    setSelectedCategory(selectedCategory || null)
  }

  const filteredPhotos = selectedCategory 
    ? categories.find((category) => category.id === +selectedCategory).images
    : []

  const topAnimation = {
    hidden: { y: 300, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
    },
  }
  return (
    <div id="construction" className={classes.construction}>
      <motion.div
        initial="hidden"
        whileInView="animate"
        variants={topAnimation}
        transition={{
          duration: 0.5,
        }}
        className={classes.wrapper}
      >
        <div className={classes.row}>
          <h1 className={classes.h1}>ХОД СТРОИТЕЛЬСТВА</h1>
          <div className={classes.select}>
            <select onChange={handleCategoryChange} className={classes.year}>
              <option value="">Все</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={classes.swiper}>
          <Swiper
            slidesPerView={2}
            navigation={true}
            loop
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
            pagination={{
              type: 'fraction',
            }}
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            modules={[Pagination, Navigation]}
            className={classes.slider}
            onAfterInit={(swiper) => {
              console.log(swiper.activeIndex)
              console.log(swiper.slides[swiper.activeIndex])
            }}
            onSlideChange={(swiper) => {
              console.log(swiper.activeIndex)
              console.log(swiper.slides[swiper.activeIndex])
            }}
          >
            {(selectedCategory === null ? allPhotoReport : filteredPhotos).map((photo) => (
              <SwiperSlide key={photo.id} className={classes.slide}>
                <img
                  className={classes.builder}
                  src={photo.image}
                  alt={photo.alt}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </motion.div>
    </div>
  )
}

export default Construction
